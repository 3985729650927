import { ReactNode, memo, useEffect, useState, useCallback } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { AppHeaderProps, BeAppFooter, BeAppHeaderHeight, BeAppSecondaryHeaderHeight, BeSideNav } from './components';
import { useBreakpoint, useDebugAttrs } from '../hooks';
import { ANCHOR, Drawer } from 'baseui/drawer';
import { useRouter } from 'next/router';
import { Layer } from 'baseui/layer';
import { RouteEnum } from '@benefeature/shared-common';
import { StatefulMenu } from 'baseui/menu';
import { Cell, Grid } from 'baseui/layout-grid';
import { Navigation } from 'baseui/side-navigation';
import { BeButtonLink } from '../forms';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import isEqual from 'lodash-es/isEqual';
import dynamic from 'next/dynamic';
import useWindowScroll from 'react-use/lib/useWindowScroll';
import { Session } from 'next-auth';
import { calculateNavItems } from '../helpers/LayoutHelpers';
import { PageNavItem } from '@benefeature/shared-types';
import { useSideNav } from '../hooks/useSideNav';
import { DEFAULT_SCROLL_BUFFER_PX } from '../models';

// Must import dynamic with no SSR to prevent severe hydration mismatches
// Lots of user-specific stuff in the header and its submenus
const BeAppHeader = dynamic(() => import('./components/BeAppHeader').then((x) => x.BeAppHeader), {
  ssr: false,
});

// Used when on a smaller viewport with pagen nav.
// Height should include sum of border widths as well.
const BeSmallPageNavHeight = 46;

const scrollDebounceMs = 500;

export enum BackgroundImages {
  FEATURES_BACKGROUND_ACCENT = '/pages/marketing/features-background-accent.svg',
  LANDING_INSURANCE_GRAPHIC = '/pages/marketing/landing-insurance-graphic.png',
  ADMIN_TILED = "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='200px' width='240px'><text x='100' y='50' fill='%23EEEEEE' font-size='30' font-family='sans-serif'>Admin</text></svg>\")",
}

export type BeMainAppLayoutProps = Omit<AppHeaderProps, 'onSideNavClick' | 'mainNavMenu'> & {
  children: ReactNode;
  backgroundColor?: string;
  secondaryHeader?: ReactNode;
  pageNavItems?: PageNavItem[];
  lastSearchURL?: string;
  noTopBottomPad?: boolean;
  session?: Session | undefined | null;
  backgroundImg?: BackgroundImages;
};

export const BeMainAppLayout = memo(
  function BeMainAppLayout({
    children,
    searchComponent,
    promoBlock,
    entityContextComponent,
    userFallbackComponent,
    userMenu,
    backgroundColor,
    secondaryHeader,
    lastSearchURL,
    noTopBottomPad,
    dismissAllNotificationsFn,
    downloadExportFn,
    pageNavItems,
    session,
    backgroundImg = BackgroundImages.FEATURES_BACKGROUND_ACCENT,
    ...props
  }: BeMainAppLayoutProps) {
    const [section] = useDebugAttrs('BeMainAppLayout');
    const [css, theme] = useStyletron();
    const router = useRouter();
    const breakpoint = useBreakpoint();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const onLogoClicked = () => {
      if (breakpoint === 'xlarge') {
        router.push(session?.user ? RouteEnum.DASHBOARDS : RouteEnum.HOME);
      } else {
        setIsMenuOpen(!isMenuOpen);
      }
    };

    /* Handling of scrolling and active items */
    const [activeItemId, setActiveItemId] = useState<string>('');
    const validSectionsAndItems = useSideNav(pageNavItems);
    const [pageNavSelectOptions, setPageNavSelectOptions] = useState([]);
    // noinspection FunctionWithMultipleLoopsJS - multiple loops expected and appropriate
    useEffect(() => {
      if (validSectionsAndItems.pageNavItemsFiltered && validSectionsAndItems.pageNavItemsFiltered.length > 0) {
        const tmpNavSelectOptions = [];
        validSectionsAndItems.pageNavItemsFiltered.forEach((item) => {
          tmpNavSelectOptions.push({ id: item.itemId, value: item.title, label: item.title, href: `#${item.itemId}` });
          if (item.subNav?.length > 0) {
            item.subNav.forEach((subNavItem) => {
              tmpNavSelectOptions.push({
                id: subNavItem.itemId,
                value: subNavItem.title,
                label: `- ${subNavItem.title}`,
                href: `#${subNavItem.itemId}`,
              });
            });
          }
        });
        setPageNavSelectOptions(tmpNavSelectOptions);
      }
    }, [validSectionsAndItems.pageNavItemsFiltered]);

    // Scroll to the fragment/anchor if it hasn't been scrolled yet
    // This could either be due to a new page load or a changed fragment/anchor
    const [scrolledToAnchor, setScrolledToAnchor] = useState<string>(null);
    const [timerID, setTimerID] = useState(null);
    const contentCallbackRef = useCallback(
      (node) => {
        if (!node) {
          return;
        }
        const resizeObserver = new ResizeObserver(() => {
          clearTimeout(timerID);
          if (window.location.hash && (!scrolledToAnchor || scrolledToAnchor !== window.location.hash)) {
            setTimerID(
              // Allow a short debounce for further changes to the content ref size
              setTimeout(() => {
                const elem = document.getElementById(window.location.hash.slice(1));
                if (elem) {
                  elem.scrollIntoView({ behavior: 'smooth' });
                  setScrolledToAnchor(window.location.hash);
                }
              }, scrollDebounceMs)
            );
          }
        });
        resizeObserver.observe(node);
      },
      [timerID, scrolledToAnchor]
    );

    // Handle the content top offset
    const [contentTopOffset, setContentTopOffset] = useState(100);
    // noinspection FunctionWithMultipleLoopsJS - multiple loops expected and appropriate
    useEffect(() => {
      setContentTopOffset(
        BeAppHeaderHeight +
          (secondaryHeader ? BeAppSecondaryHeaderHeight : 0) +
          (validSectionsAndItems.pageNavItemsFiltered?.length > 0 && breakpoint !== 'xlarge' ? BeSmallPageNavHeight : 0)
      );
    }, [secondaryHeader, breakpoint, validSectionsAndItems.pageNavItemsFiltered]);

    // X value is unused
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { /* x is unused */ x: _windowScrollX, y: windowScrollY } = useWindowScroll();
    useEffect(() => {
      if (windowScrollY != null && validSectionsAndItems.sections) {
        /* Page nav item handling */
        if (pageNavItems?.length) {
          if (validSectionsAndItems.sections?.length) {
            // Slice and reverse the sections (reverse is an in-place operation)
            // Allows iteration from the lowest position back through the earliest which facilitates a break once the condition is met
            let activeItemID = null;
            for (const section of validSectionsAndItems.sections.slice().reverse()) {
              /* Check if the current scroll position is near the top of the section
               * Must add in the viewport height as well */
              if (windowScrollY + DEFAULT_SCROLL_BUFFER_PX >= section['offsetTop']) {
                activeItemID = section.id;

                // Break out of the for loop, no need to continue
                // noinspection BreakStatementJS
                break;
              }
            }

            // Default the active item ID to the first section's ID (if available)
            if (!activeItemID) {
              activeItemID = validSectionsAndItems.sections?.[0]?.id;
            }

            // Set the active item state
            setActiveItemId(activeItemID ? `#${activeItemID}` : null);
          }
        }
      }
    }, [windowScrollY, validSectionsAndItems.sections, pageNavItems]);

    const [mainNavMenuState, setMainNavMenuState] = useState({ items: [] });
    useEffect(() => {
      setMainNavMenuState(calculateNavItems(session, lastSearchURL, breakpoint, router));
    }, [router, breakpoint, session, lastSearchURL]);

    const contentStyle = css({
      backgroundColor:
        backgroundImg && !backgroundColor ? 'transparent' : theme.colors[backgroundColor] || backgroundColor,
      top: `${contentTopOffset}px`,
      position: 'relative',
      minHeight: '100vh',
    });

    const primaryNavStyle = css({
      position: 'fixed',
      top: '0',
      width: '100%',
    });

    const secondaryNavStyle = css({
      position: 'fixed',
      top: `${BeAppHeaderHeight}px`,
      height: `${BeAppSecondaryHeaderHeight}px`,
      width: '100%',
    });

    const pageNavSmallMediumStyle = css({
      height: `${BeSmallPageNavHeight}px`,
      left: '0',
      top: `${BeAppHeaderHeight + (secondaryHeader ? BeAppSecondaryHeaderHeight : 0)}px`,
      width: '100%',
      position: 'fixed',
      boxSizing: 'border-box',
      borderBottomColor: theme.colors.borderOpaque,
      borderBottomWidth: '2px',
      borderBottomStyle: 'solid',
      backgroundColor: theme.colors.backgroundPrimary,
    });

    return (
      <Block {...section('root')}>
        {/* Apply the default background if requested */}
        {backgroundImg ? (
          <Block
            position={'fixed'}
            top={0}
            left={0}
            right={0}
            height={'100vh'}
            backgroundImage={
              backgroundImg?.startsWith('url(')
                ? backgroundImg
                : `url("${process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}${backgroundImg}")`
            }
            {...(backgroundImg?.startsWith('url(')
              ? {}
              : {
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                })}
            overrides={{ Block: { style: { zIndex: -1 } } }}
          />
        ) : null}

        <Block
          ref={contentCallbackRef}
          id={'content-block'}
          key={'content-block'}
          className={contentStyle}
          {...section('content')}
        >
          <Grid
            gridGutters={0}
            gridMargins={0}
            gridGaps={0}
            overrides={{
              Grid: {
                style: {
                  minHeight: '100vh',
                  ...(noTopBottomPad
                    ? {}
                    : {
                        paddingTop: '20px',
                        paddingBottom: '40px',
                      }),
                },
              },
            }}
          >
            {validSectionsAndItems.pageNavItemsFiltered?.length > 0 && breakpoint === 'xlarge' ? (
              <Cell
                {...section('content-pageNavItems-cell')}
                key={'content-pageNavItems-cell'}
                span={[2, 4, 8, 2]}
                overrides={{
                  Cell: {
                    props: {
                      id: 'content-pageNavItems-cell',
                    },
                    style: {
                      alignSelf: 'flex-start',
                      position: 'sticky',
                      height: '100vh',
                      backgroundColor: theme.colors.backgroundPrimary,
                      top: `${BeAppHeaderHeight + (secondaryHeader ? BeAppSecondaryHeaderHeight : 0)}px`,
                      display: 'block',
                    },
                  },
                }}
              >
                <Navigation
                  {...section('content-pageNavItems-navigation')}
                  key={'content-pageNavItems-navigation'}
                  items={validSectionsAndItems.pageNavItemsFiltered}
                  activeItemId={activeItemId}
                  overrides={{
                    NavItem: {
                      style: ({ $theme, $active }) => ({
                        ...($active
                          ? {
                              borderLeftColor: $theme.colors.blue500,
                              color: $theme.colors.blue500,
                              fontWeight: '600',
                              ':hover': {
                                color: $theme.colors.blue500,
                              },
                            }
                          : {}),
                        borderLeftWidth: '4px',
                        marginLeft: '8px',
                      }),
                    },
                  }}
                />
              </Cell>
            ) : null}

            <Cell
              {...section('content-children-wrapper')}
              key={'content-children-wrapper'}
              overrides={{
                Cell: {
                  props: {
                    id: 'content-children-wrapper',
                  },
                  style: {
                    ...(validSectionsAndItems.pageNavItemsFiltered?.length > 0
                      ? {
                          paddingLeft: '20px',
                          borderLeft: `solid 2px ${theme.colors.borderOpaque}`,
                        }
                      : {}),
                  },
                },
              }}
              span={validSectionsAndItems.pageNavItemsFiltered?.length > 0 ? [2, 4, 8, 10] : [2, 4, 8, 12]}
            >
              {children}
            </Cell>
          </Grid>

          {/* Render the footer within the content block to ensure it shows up under the appropriate layer */}
          <BeAppFooter />
        </Block>

        {/* IMPORTANT: render all headers after content to ensure they're painted on top (layer order is correct without adjustment) */}
        <Block {...section('primary-nav-app-header-wrapper')} className={primaryNavStyle}>
          <BeAppHeader
            userMenu={userMenu}
            entityContextComponent={entityContextComponent}
            searchComponent={searchComponent}
            promoBlock={promoBlock}
            userFallbackComponent={userFallbackComponent}
            mainNavMenu={mainNavMenuState}
            onLogoClicked={onLogoClicked}
            dismissAllNotificationsFn={dismissAllNotificationsFn}
            downloadExportFn={downloadExportFn}
          />
        </Block>

        {secondaryHeader && (
          <Block className={secondaryNavStyle} {...section('secondary-nav')}>
            {secondaryHeader}
          </Block>
        )}

        {validSectionsAndItems.pageNavItemsFiltered?.length > 0 && breakpoint !== 'xlarge' ? (
          <Block className={pageNavSmallMediumStyle} {...section('page-nav-section')}>
            <StatefulPopover
              returnFocus
              dismissOnClickOutside
              placement={PLACEMENT.bottomLeft}
              popoverMargin={0}
              popperOptions={{ modifiers: { flip: { enabled: false } } }}
              triggerType={TRIGGER_TYPE.click}
              overrides={{
                Inner: { style: ({ $theme }) => ({ backgroundColor: $theme.colors.white }) },
              }}
              content={({ close }) => {
                return (
                  <StatefulMenu
                    items={pageNavSelectOptions}
                    onItemSelect={({ item }) => {
                      router.push(item.id as string, null, { shallow: true }).then(() => {
                        setActiveItemId(item.id as string);
                      });
                      close();
                    }}
                    overrides={{
                      List: { style: { ':focus': { outline: 'none' } } },
                      ListItem: { style: { fontSize: '16px' } },
                    }}
                  />
                );
              }}
            >
              {/* Use a block as a target for the stateful popover */}
              <Block>
                <BeButtonLink
                  kind={'tertiary'}
                  size={'default'}
                  overrides={{
                    EndEnhancer: {
                      style: {
                        marginLeft: 0,
                      },
                    },
                    BaseButton: {
                      style: ({ $theme }) => ({
                        paddingRight: '8px',
                        color: $theme.colors.contentSecondary,
                      }),
                    },
                  }}
                  endEnhancer={<ExpandMoreRoundedIcon />}
                >
                  {pageNavSelectOptions?.find((item) => item?.id === activeItemId)?.label}
                </BeButtonLink>
              </Block>
            </StatefulPopover>
          </Block>
        ) : null}

        {isMenuOpen && (
          <Layer>
            <Drawer renderAll anchor={ANCHOR.left} onClose={() => setIsMenuOpen(false)} isOpen={isMenuOpen}>
              <BeSideNav isCollapsed={false} {...mainNavMenuState} />
            </Drawer>
          </Layer>
        )}
      </Block>
    );
  },
  (a, b) => isEqual(a, b)
);
